import * as React from 'react'
import PropTypes from 'prop-types'
import { domToReact } from 'html-react-parser'

export const parseSpanPersonalisation = (param) => {
    const spec = {
        replace: (domNode) => {
            if (domNode.name === 'span') {
                return (
                    <span>
                        {param ? `${param}'s` : domToReact(domNode.children)}
                    </span>
                )
            }
        }
    }

    return spec
}

parseSpanPersonalisation.propTypes = {
    /**
     * URL parameter string to use for the personalisation
     */
    param: PropTypes.string
}
