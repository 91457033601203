import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { UserContext } from '/src/contexts/UserContext'
import parse from 'html-react-parser'
import { convertToBgImage } from 'gbimage-bridge'
import { parseSpanPersonalisation } from '/src/functions/parse/spanPersonalisation'
import joinClasses from '/src/functions/joinClasses'
import backgroundImage from '/src/dummy-data/images/backgroundImage'
import './styles.scss'

const BackgroundImage = loadable(() => import('gatsby-background-image'))
const BackBreadcrumb = loadable(() => import('/src/components/Structure/Breadcrumb/BackBreadcrumb'))
const Breadcrumb = loadable(() => import('/src/components/Structure/Breadcrumb/Breadcrumb'))
const Image = loadable(() => import('/src/components/Media/Image'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const Snowfall = loadable(() => import('react-snowfall'))

const PageHeader = ({
    variant,
    pageTitle,
    breadcrumb,
    headingLevel,
    altTitle,
    description,
    addImage,
    image,
    backgroundImage,
    node,
    addSnow
}) => {
    const [user] = useContext(UserContext)

    const bgImageFile = backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
    const bgImage = convertToBgImage(bgImageFile)

    const HeadingTag = headingLevel

    const classes = ['c-page-header', ...(variant ? [`c-page-header--${variant}`] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <>
            <Section variant={'other'} size={'sm'} className={concatenatedClasses}>
                {addSnow && <Snowfall />}

                <div className="c-page-header__inner">
                    <HeadingTag className="c-page-header__title">
                        {altTitle ? parse(altTitle, parseSpanPersonalisation(user.name)) : pageTitle}
                    </HeadingTag>

                    {description && <div className="c-page-header__description">{parse(description)}</div>}
                </div>

                {addImage && (
                    <div className="c-page-header__node">
                        <Image className={'c-page-header__image'} data={image} />
                    </div>
                )}

                {node && <div className="c-page-header__node">{node}</div>}

                {bgImage && (
                    <BackgroundImage
                        Tag={'span'}
                        className={'c-page-header__background'}
                        {...bgImage}
                        preserveStackingContext
                    ></BackgroundImage>
                )}
            </Section>

            {breadcrumb.type === 'back' ? (
                <BackBreadcrumb size={'narrower'} title={breadcrumb.backTitle} uri={breadcrumb.uri} />
            ) : breadcrumb.type === 'standard' && breadcrumb.pageParent ? (
                <Breadcrumb size={'standard'} pageTitle={pageTitle} pageParent={breadcrumb.pageParent} />
            ) : null}
        </>
    )
}

PageHeader.propTypes = {
    variant: PropTypes.oneOf(['standard', 'centered']),
    pageTitle: PropTypes.string,
    breadcrumb: PropTypes.shape({
        pageParent: PropTypes.shape({
            node: PropTypes.shape({
                title: PropTypes.string.isRequired,
                uri: PropTypes.string.isRequired,
                wpParent: PropTypes.shape({
                    node: PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        uri: PropTypes.string.isRequired
                    })
                })
            })
        })
    }),
    headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']),
    altTitle: PropTypes.string,
    description: PropTypes.string,
    addImage: PropTypes.bool,
    image: PropTypes.object,
    backgroundImage: PropTypes.object,
    /**
     * Optional additional node to display
     */
    node: PropTypes.node,
    addSnow: PropTypes.bool
}

PageHeader.defaultProps = {
    pageTitle: 'Page Title',
    breadcrumb: {
        pageParent: {
            node: {
                title: 'Page Name',
                uri: '/page-url/'
            }
        }
    },
    headingLevel: 'h1',
    addImage: false,
    backgroundImage: backgroundImage,
    addSnow: false
}

export default PageHeader

export const query = graphql`
    fragment PageHeader on WpPage {
        pageHeader: acfPageHeader {
            fieldGroupName
            variant
            altTitle
            description
            addImage
            image {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 350)
                    }
                }
                altText
                title
            }
            backgroundImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 1500)
                    }
                }
            }
        }
    }
    fragment PageHeaderForPageBuilder on WpPage_Acfpagebuilder_Layouts_PageHeader {
        fieldGroupName
        variant
        altTitle
        description
        addImage
        image {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 350)
                }
            }
            altText
            title
        }
        backgroundImage {
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 1500)
                }
            }
        }
    }
`
